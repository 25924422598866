import { components } from "~/openapi/schema";

// returns references sorted by priority, secondary by year
export const sortReferences = (
  references: components["schemas"]["Reference"][],
) => {
  return references.sort((a, b) => {
    if (a.priority === b.priority) {
      return Number(b.year) - Number(a.year);
    }
    return b.priority - a.priority;
  });
};
