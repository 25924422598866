import { useCallback, useEffect, useRef, useState } from "react";

export function useAutoslideInterval({
  frequency,
  onNextTick,
  photosLength,
  autoPlay = true,
}: {
  photosLength: number;
  onNextTick: () => void;
  frequency: number;
  autoPlay?: boolean;
}) {
  const [isLaunched, setIsLaunched] = useState(false);

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (autoPlay) {
      startInterval();
    }

    return () => {
      stopInterval();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    console.log("starting interval", frequency);
    intervalRef.current = setInterval(() => {
      onNextTick();
    }, frequency);
    setIsLaunched(true);
  }, [frequency, onNextTick]);

  const resetInterval = useCallback(() => {
    if (!photosLength) return;

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      onNextTick();
    }, frequency);
  }, [frequency, onNextTick, photosLength]);

  const stopInterval = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setIsLaunched(false);
  }, []);

  return { isLaunched, startInterval, resetInterval, stopInterval };
}
