import { components } from "~/openapi/schema";
import { strapiRepository } from "~/openapi/strapiRepository";
import { useAutoslideInterval } from "~/components/homepage/hero-section/useAutoslideInterval";
import { useCallback, useEffect, useState } from "react";
import { cn } from "~/lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useIntersectionObserver, useIsFirstRender } from "@uidotdev/usehooks";
import { Link } from "@remix-run/react";
import { Youtube } from "~/components/ui/icons/Youtube";
import { useIsMdScreen } from "~/lib/hooks/useIsMdrScreen";
import { ChevronSharpRight } from "~/components/ui/icons/ChevronSharpRight";
import { Button } from "~/components/ui/button";
import BlurFade from "~/components/ui/blur-fade";

const IMAGES_CHANGE_FREQUENCY = 3000; // in milliseconds
const BLUR_DURATION = 0.08;

export const ReferenceBox = ({
  reference,
  className,
  showCategory = false,
  showYear = true,
  mode = "default",
}: {
  reference: components["schemas"]["Reference"];
  className?: string;
  showCategory?: boolean;
  showYear?: boolean;
  mode?: "default" | "compact";
}) => {
  const isFirstRender = useIsFirstRender();
  const isMd = useIsMdScreen();
  const [ref, entry] = useIntersectionObserver({
    threshold: 0.5,
    root: null,
    rootMargin: "-18% 0px -18% 0px",
  });

  const { getImageUrl } = strapiRepository;
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const onNextTick = useCallback(
    (direction: "next" | "previous" = "next") => {
      setCurrentPhotoIndex((prev) => {
        if (!reference.photos) return 0;
        if (direction === "next") {
          return prev === reference.photos.length - 1 ? 0 : prev + 1;
        }
        return prev === 0 ? reference.photos.length - 1 : prev - 1;
      });
    },
    [reference.photos],
  );

  const frequency = isMd
    ? IMAGES_CHANGE_FREQUENCY
    : IMAGES_CHANGE_FREQUENCY * 2;

  const { isLaunched, stopInterval, resetInterval, startInterval } =
    useAutoslideInterval({
      frequency,
      onNextTick,
      photosLength: reference?.photos?.length ?? 0,
      autoPlay: false,
    });

  useEffect(() => {
    if (isMd) return;
    if (isLaunched) {
      if (!entry?.isIntersecting) {
        stopInterval();
      }
    } else if (entry?.isIntersecting) {
      startInterval();
    }
  }, [isMd, isLaunched, entry?.isIntersecting, startInterval, stopInterval]);

  // Prefetch the next and previous image (if available)
  useEffect(() => {
    if (!reference.photos) return;
    const nextIndex =
      currentPhotoIndex === reference.photos.length - 1
        ? 0
        : currentPhotoIndex + 1;
    const previousIndex =
      currentPhotoIndex === 0
        ? reference.photos.length - 1
        : currentPhotoIndex - 1;
    const imagesToFetch = [nextIndex, previousIndex];
    imagesToFetch.forEach((index) => {
      if (!reference.photos) return;
      if (reference.photos[index]) {
        const imageUrl = getImageUrl(reference.photos[index], "medium");
        const img = new Image(); // Using the Image constructor to preload the image
        img.src = imageUrl; // This will start the preload
      }
    });
  }, [currentPhotoIndex, getImageUrl, reference.photos]);

  return (
    <>
      <Link
        ref={ref}
        to={`/reference/${reference.category?.slug}/${reference.slug}`}
        className={cn(
          "group relative flex size-full select-none flex-row justify-center",
          className,
        )}
        onMouseEnter={() => {
          startInterval();
        }}
        onMouseLeave={() => {
          stopInterval();
        }}
      >
        {reference?.photos ? (
          <>
            <div className={"absolute size-full min-h-16 min-w-16"}>
              {reference?.photos &&
                reference.photos.map((photo, index) => {
                  return (
                    <AnimatePresence key={`${photo.id}-${reference.id}`}>
                      {index === currentPhotoIndex && (
                        <motion.img
                          key={`reference-photo-${photo.id}-${reference.id}`}
                          src={getImageUrl(photo!, "medium")}
                          alt={reference.title}
                          className={"absolute size-full object-cover"}
                          initial={isFirstRender ? {} : { opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{
                            opacity: 0,
                            transition: {
                              duration: 0.3, // Specify exit animation duration
                              ease: "easeInOut",
                            },
                          }}
                          transition={{
                            duration: 0.3,
                            ease: "easeInOut",
                          }}
                        />
                      )}
                    </AnimatePresence>
                  );
                })}

              <AnimatePresence>
                {isLaunched && reference.photos.length > 1 && (
                  <BlurFade duration={BLUR_DURATION} className={"h-full"}>
                    <div className={"absolute bottom-0 left-2 top-1/2"}>
                      <Button
                        variant={"ghost"}
                        size={"icon"}
                        className={
                          "h-8 w-8 rotate-180 bg-foreground/60 hover:bg-foreground/80"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          resetInterval();
                          onNextTick("previous");
                        }}
                      >
                        <ChevronSharpRight className={"text-background"} />
                      </Button>
                    </div>

                    <div className={"absolute bottom-0 right-2 top-1/2"}>
                      <Button
                        variant={"ghost"}
                        size={"sm"}
                        className={
                          "h-8 w-8 bg-foreground/60 hover:bg-foreground/80"
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          resetInterval();
                          onNextTick();
                        }}
                      >
                        <ChevronSharpRight className={"text-background"} />
                      </Button>
                    </div>
                  </BlurFade>
                )}
              </AnimatePresence>
            </div>

            <AnimatePresence>
              {isLaunched && reference.photos.length > 1 && (
                <BlurFade
                  duration={BLUR_DURATION}
                  className={"absolute bottom-2 mx-10 py-1"}
                >
                  <div
                    className={
                      "flex flex-wrap justify-center gap-2 px-3 transition-all duration-100"
                    }
                  >
                    {reference.photos.map((photo, index) => {
                      const isCurrentPhoto = index === currentPhotoIndex;
                      return (
                        <motion.div
                          key={"photo-dot-" + photo.id}
                          initial={{ width: isCurrentPhoto ? 30 : 12 }}
                          animate={{ width: isCurrentPhoto ? 30 : 12 }}
                          transition={{
                            duration: 0.1,
                            ease: "linear",
                          }}
                          className={cn(
                            "relative h-[12px] overflow-hidden rounded-full",
                            {
                              "cursor-pointer": !isCurrentPhoto,
                            },
                            index < currentPhotoIndex
                              ? "bg-gray-900"
                              : "bg-gray-200",
                          )}
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentPhotoIndex(index);
                            resetInterval();
                          }}
                        >
                          {isCurrentPhoto && (
                            <motion.span
                              key={"photo-dot-bg-" + photo.id}
                              initial={{ width: 0 }}
                              animate={{ width: 30 }}
                              transition={{
                                duration: frequency / 1000,
                                ease: "linear",
                              }}
                              className={
                                "absolute size-full w-full bg-gray-900"
                              }
                            ></motion.span>
                          )}
                        </motion.div>
                      );
                    })}
                  </div>
                </BlurFade>
              )}
            </AnimatePresence>
          </>
        ) : (
          <div className={"absolute size-full bg-gray-200"}></div>
        )}
        <div
          className={
            "z-10 flex w-full flex-col self-start px-2 py-4 text-white"
          }
        >
          <h3
            className={cn(
              "w-fit bg-foreground/90 px-3 py-1",
              mode === "default" ? "text-xl md:text-3xl" : "text-lg md:text-xl",
            )}
          >
            {reference.title}
          </h3>

          {reference.location && (
            <span
              className={cn(
                "w-fit bg-foreground/90 px-2 py-0.5 text-sm md:text-base",
                mode === "default"
                  ? "mt-0.5 text-sm md:text-base"
                  : "text-xs md:text-sm",
              )}
            >
              {reference.location}
            </span>
          )}

          {reference.year && showYear && (
            <span
              className={cn(
                "w-fit bg-foreground/90 px-2 py-0.5 text-xs md:text-sm",
              )}
            >
              {reference.year}
            </span>
          )}

          {showCategory && reference.category && (
            <span
              className={cn(
                "w-fit bg-foreground/90 px-2 py-0.5 text-xs font-light md:text-sm",
              )}
            >
              {reference.category.name}
            </span>
          )}

          {reference.youtube_link && (
            <div
              className={
                "w-fit bg-foreground/90 px-2 pb-1 text-sm md:text-base"
              }
            >
              <Youtube className={cn("size-3 fill-white")} />
            </div>
          )}
        </div>
      </Link>
    </>
  );
};
