import { useEffect, useState } from "react";

export function useIsMdScreen() {
  const [isMd, setIsMd] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 768px)"); // Tailwind's "md" breakpoint
    const updateIsMd = () => setIsMd(mediaQuery.matches);

    updateIsMd();
    mediaQuery.addEventListener("change", updateIsMd);

    return () => mediaQuery.removeEventListener("change", updateIsMd);
  }, []);

  return isMd;
}
